<template>
  <div class="home">
    <v-progress-linear :active="getLoaderStatus" indeterminate fixed top color="primary" height="5px" style="z-index: 500;"></v-progress-linear>
    <mice-navigation @vendor-list-mini="minimizeList"></mice-navigation>
    <top-nav-bar :mini="mini"></top-nav-bar>
    <v-main class="details-content">
      <v-row no-gutters>
        <v-col cols="3" class="default settings-nav">
          <v-list flat class="default" subheader>
            <v-list-item-group color="primary" mandatory v-model="activeSetting" active-class="active-setting">
              <v-list-item value="organization-management">
                <v-list-item-icon>
                  <v-icon>mdi-office-building</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Organization Management</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item value="user-management">
                <v-list-item-icon>
                  <v-icon>mdi-account-multiple</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>User Management</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item value="profile">
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Profile</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!--<v-list-item value="logs">
                <v-list-item-icon>
                  <v-icon>mdi-history</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2">Logs</v-list-item-title>
                </v-list-item-content>
              </v-list-item>-->
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col class="pa-5 content-wrapper" cols="9">
          <organization-management v-if="activeSetting === 'organization-management'"></organization-management>
          <user-management v-if="activeSetting === 'user-management'"></user-management>
          <profile v-if="activeSetting === 'profile'"></profile>
        </v-col>
      </v-row>

    </v-main>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
const TopNavBar = () => import(/* webpackChunkName: "top-nav-bar" */'@/components/TopNavBar');
const OrganizationManagement = () => import(/* webpackChunkName: "organization-management" */'./OrganizationManagement');
const UserManagement = () => import(/* webpackChunkName: "user-management" */'./UserManagement');
const Profile = () => import(/* webpackChunkName: "profile" */'./Profile');
export default {
  name: 'Home',
  components: { TopNavBar, OrganizationManagement, UserManagement, Profile },
  data: () => ({
    mini: false,
    navigation: 0,
    listingDrawer: true,
    activeSetting: null,
  }),
  async created(){
    await this.loadAccounts();
    await this.loadOrganizations();
    await this.loadUsers();
  },
  computed: {
    ...mapGetters(['getLoaderStatus']),
  },
  methods: {
    minimizeList(status) {
      this.mini = status;
    },
    ...mapActions(['logout', 'loadAccounts', 'loadOrganizations', 'loadUsers']),
  },
}
</script>
<style scoped>
.details-content {
  width: 100%;
  padding-left: 250px;
  height: 100vh;
  background-color: white;
}

.settings-nav {
  height: calc(100vh - 48px);
}

.content-wrapper {
  height: calc(100vh - 48px);
  overflow-y: scroll;
}
.active-setting {
  font-weight: 600;
}
</style>
